$theme-colors: (
  "chatBot-theme": #071449,
  "navbar-color": #0f265a,
  "item-bg": #ffffff,
);
@import "node_modules/bootstrap/scss/bootstrap";
body {
  padding: 0px !important;
  margin: 0px !important;
}
::-webkit-scrollbar {
  width: 0.111rem;
  height: 0rem;
}
::-webkit-scrollbar-track {
  background: theme-color("site-secondary1");
}
::-webkit-scrollbar-thumb {
  background-color: #cecece; /* color of the scroll thumb */
  border-radius: 1.25rem; /* roundness of the scroll thumb */
}
::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}
$weights: 100 200 300 400 500 500 600 700 800;
@each $weight in $weights {
  .fw--#{$weight} {
    font-weight: $weight;
  }
}
@for $size from 9 through 96 {
  .fs--#{$size} {
    font-size: $size/16 + rem;
  }
}
.chatbot-screen {
  border-top: 10px solid black;
  border-bottom: 10px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 25px !important;
  height: 380px !important;
  width: 70% !important;
  margin-bottom: 10px !important;
  @media screen and (max-width: 1030px) {
    width: 70% !important;
  }
  @media screen and (max-width: 991.33px) {
    width: 50% !important;
  }
  @media screen and (max-width: 768px) {
    width: 50% !important;
  }
  @media screen and (max-width: 767px) {
    width: 50% !important;
  }
  @media screen and (max-width: 568px) {
    width: 50% !important;
  }
  @media screen and (max-width: 500px) {
    width: 70% !important;
  }
  @media screen and (max-width: 450px) {
    width: 70% !important;
  }
  @media screen and (max-width: 400px) {
    width: 75% !important;
  }
  @media screen and (max-width: 350px) {
    width: 85% !important;
  }
  @media screen and (max-width: 330px) {
    width: 90% !important;
  }
  position: relative;
}

.rounded-pill-footer {
  position: absolute;
  bottom: 10px; /* Adjust this value for desired spacing from the bottom */
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  border-radius: 50px;
}
.icon-pin {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: #bebebe;
  color: #ffffff;
}
.rounded-pill-input {
  border: none;
  outline: none;
  background-color: transparent;
  flex-grow: 1;
  background-color: #bebebe;
  margin-left: 2px;
  padding-bottom: 10px !important;
  padding-top: 6px !important;
  border-radius: 50px;
  width: 10% !important;
  /* Adjust font size as needed */
  margin-right: 2px; /* Adjust margin as needed */
}
.rounded-pill-input::placeholder {
  font-size: 13px !important;
  color: #e2e2e2;
  padding-left: 20px !important;
}
.rounded-pill-button {
  border: none;
  outline: none;
  background-color: black;
  color: white;
  font-size: 9px !important;
  padding: 5px 10px;
  border-radius: 50px;
  cursor: pointer;
}
.chatbot-usage-button {
  background-color: #071449;
}
.chatbot-usage-button:hover {
  background-color: #f2b456 !important;
}
.chatbot-usage-button:active {
  background-color: #f2b456 !important;
}
.chat-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align bot messages to the left */
  max-height: 40vh;
  overflow: auto;
}

/* Style for user messages */
/* Style for user messages */
.user-message {
    color: black ;
    background-color: rgb(207, 203, 203);
    padding: 8px;
    border-radius: 8px;
    margin: 8px;
    text-align: left; /* Align user messages to the left */
    font-size: 11px; /* Adjust text size as needed */
}

/* Style for bot messages */
.bot-message {
  color: white;
  background-color: black;
  padding: 8px;
  border-radius: 8px;
  margin: 8px;
  text-align: right; /* Align bot messages to the right */
  font-size: 11px; /* Adjust text size as needed */
}
.user {
  align-self: flex-end;
}
.setting-tab {
  .nav-tabs .nav-link.active {
    background: #1171ef !important;

    // background-color: #f2b456 !important;
  }
  .nav-tabs .nav-link {
    background: linear-gradient(87deg, #adb5bd 0, #adaebd 100%) !important;
    color: white !important;
    font-size: 18px;
    cursor: pointer;
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  //   .nav {
  //     .nav-tabs {
  //   .nav-item {
  //     background: #1c48af !important;
  //     border-top-right-radius: 2px;
  //     border-top-left-radius: 2px;
  //     color: white !important;
  //     margin: 2px;
  //     cursor: pointer;
  //     //   }
  //     // }
  //   }
}
.cursor-pointer {
  cursor: pointer;
}
