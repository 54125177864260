.form-Height {
  height: 511px !important;
  width: 300px !important;
}
.chatbox {
  overflow-y: auto;
  height: 344px;
  padding: 30px 20px 0px;
}
.chatboxtwo {
  overflow-y: auto;
  height: 344px;
  margin-top: 10px;
  padding: 30px 20px 0px;
}
.rounded-pill-input::placeholder {
  font-size: 13px !important;
  color: #000000 !important;
  padding-left: 20px !important;
}
.logo {
  max-width: 100%;
  height: auto;
  /* border-radius: 50%; */
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.icon-button {
  border: none;
  background: none;
  padding: 0;
  font-size: inherit;
  cursor: pointer;
}
.spinning-icon {
  display: inline-block;
  animation: spin 1s linear infinite;
}
.cursor-pointer {
  cursor: pointer !important;
}

/* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* For Firefox */
.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #dddcdc #f1f1f1;
}

.scrollbar:hover {
  scrollbar-color: #b1b0b0 #f1f1f1;
}

.UserMessages {
  color: #ece9e9;
  cursor: default;
  align-self: flex-start;
  background: #292727;
  border-radius: 10px 0px 10px 10px;
  padding: 5px 9px 5px 9px;
  font-size: 12px;
  margin: 0 10px 7px 0;
  max-width: 75%;
  overflow-wrap: break-word !important;
  overflow-wrap: anywhere !important;
}

.AIMessages {
  color: #070707;
  cursor: default;
  align-self: flex-end;
  background: #e9e9e9;
  border-radius: 0 10px 10px 10px;
  padding: 5px 9px 5px 9px;
  font-size: 12px;
  margin: 0 10px 7px 0;
  max-width: 75%;
  margin-left: 9px;
  overflow-wrap: break-word !important;
  overflow-wrap: anywhere !important;
}
